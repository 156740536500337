import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
  SimpleFormIterator,
  useRecordContext,
  useSimpleFormIteratorItem,
} from "react-admin";
import { useEffect, useState } from "react";

export type TOption = { name: string; values: string[] };

export const formatOptions = (v: any = {}) =>
  Object.keys(v).map((key) => ({ name: key, values: v[key] }));

export const parseOptions = (arr: TOption[]) =>
  arr.reduce(
    (opts, o) => ({
      ...opts,
      [o.name]: o.values.map((v) => v.trim()),
    }),
    {}
  );

export const OptionsInput = () => {
  const group = useRecordContext();

  return (
    <ArrayInput
      source="options"
      defaultValue={[]}
      validate={[uniqueOption]}
      fullWidth
    >
      <SimpleFormIterator disableClear fullWidth>
        <FormDataConsumer>
          {({ getSource }) => {
            const { index } = useSimpleFormIteratorItem();
            const [choices, setChoices] = useState<
              { id: string; name: string }[]
            >([]);

            return (
              <>
                <h3>Opción {index + 1}</h3>
                <AutocompleteInput
                  label="Nombre"
                  source={getSource!("name")}
                  choices={choices}
                  onKeyDown={(e) => {
                    if (e.key === ".") e.preventDefault();
                  }}
                  validate={[required()]}
                  onCreate={(filter = "") => {
                    const newChoice = {
                      id: filter,
                      name: filter,
                    };
                    setChoices((prev) => [...prev, newChoice]);
                    return newChoice;
                  }}
                  fullWidth
                />
              </>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ scopedFormData = {}, getSource }) => {
            const [values, setValues] = useState<any[]>([]);

            useEffect(() => {
              setValues(
                (
                  group?.options.find(
                    (o: any) => o.name === scopedFormData.name
                  )?.values ?? []
                ).map((val: string) => ({
                  id: val,
                  name: val,
                }))
              );
            }, [scopedFormData.name]);

            return (
              <AutocompleteArrayInput
                label="Valores"
                source={getSource!("values")}
                choices={values}
                onCreate={(filter = "") => {
                  const newChoice = {
                    id: filter,
                    name: filter,
                  };
                  setValues((prev) => [...prev, newChoice]);
                  return newChoice;
                }}
                fullWidth
              />
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const uniqueOption = (value: TOption[]) => {
  const names = value?.map((o) => o.name).filter((n) => n) ?? [];
  const duplicates = names.filter(
    (item, index) => names.indexOf(item) != index
  );
  return duplicates.length
    ? "Hay opciones duplicadas: " + duplicates
    : undefined;
};
