import { ModalAction } from "@/components/common/ModalAction";
import { PRODUCTS, SUPPLIERS } from "@/config/resources";
import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateTimeInput,
  Form,
  FormDataConsumer,
  FunctionField,
  Labeled,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  WithRecord,
  maxValue,
  minValue,
  number,
  required,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Edit, Add, Refresh, Delete } from "@mui/icons-material";
import { FC, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  AxiosError,
  TProduct,
  TProductSupplier,
  TSupplier,
  getSeeriApi,
} from "@/api";
import { useAdmin } from "@/hooks/admin";
import { integer } from "@/utils/input-validators";
import useSWR from "swr";
import {
  SupplierDiscountAction,
  SupplierDiscountDeleteButton,
} from "@/resources/suppliers/discounts/discounts";
import { getNTName } from "@/resources/suppliers/constants";
import { formatCurrency } from "@/utils/currency";
import { SupplierDiscountsContext } from "@/resources/suppliers/discounts/supplier-discounts-context";
import { country } from "@/config/country";
import { fixApiDateStr } from "@/utils/parse-date";

export const ProductSuppliersField = () => {
  const refresh = useRefresh();
  const product = useRecordContext<TProduct>();
  const { hasPermission } = useAdmin();

  return (
    <>
      {hasPermission("product.supplier.create") && (
        <SupplierFormAction product={product} />
      )}

      <ArrayField source="suppliers">
        <Datagrid
          bulkActionButtons={false}
          expand={
            <Box sx={{ width: { xs: "100%", md: "90%", margin: "auto" } }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={6} md={4}>
                  <Labeled>
                    <NumberField source="reservedStock" emptyText="-" />
                  </Labeled>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Labeled>
                    <NumberField source="securityStock" emptyText="-" />
                  </Labeled>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Labeled>
                    <BooleanField source="disableInStockOut" emptyText="-" />
                  </Labeled>
                </Grid>
              </Grid>

              <Labeled label="Detalle de stock" fullWidth>
                <ProductSupplierStockField />
              </Labeled>

              <Labeled label="Tiempos de entrega" fullWidth>
                <DeliveryTimes />
              </Labeled>

              <Labeled label="Fecha de vencimiento">
                <FunctionField
                  render={(record: TProductSupplier) => (
                    <DateField
                      source="dueDate"
                      showTime
                      emptyText="-"
                      record={{
                        dueDate: fixApiDateStr(record.dueDate),
                      }}
                    />
                  )}
                />
              </Labeled>

              <Labeled label="Descuentos escalonados" fullWidth>
                <ProductSupplierSalesField />
              </Labeled>
              <Box sx={{ width: "100%", mb: 2 }}>
                <WithRecord
                  render={(productSupplier) => (
                    <SupplierDiscountsContext.Provider
                      value={{
                        applier: "PRODUCT",
                        supplierId: productSupplier.supplierId,
                        reload: refresh,
                      }}
                    >
                      <SupplierDiscountAction
                        referenceId={product.id as string}
                        valueType={productSupplier.sales?.[0]?.valueType}
                      />
                    </SupplierDiscountsContext.Provider>
                  )}
                />
              </Box>
            </Box>
          }
        >
          <ReferenceField
            source="supplierId"
            reference={SUPPLIERS}
            link={hasPermission("supplier.view") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="approveStatus" />
          <TextField source="supplierSku" />
          <NumberField source="marginSeeri" />
          <FunctionField
            source="supplierPrice"
            render={(productSupplier: TProductSupplier) =>
              productSupplier.supplierPrice ? (
                <NumberField source="supplierPrice" />
              ) : (
                "-"
              )
            }
          />
          <NumberField source="wholesalePrice" />
          <NumberField
            source="purchasePrice"
            label="Precio de compra"
            emptyText="-"
          />
          {country.code === "us" && (
            <NumberField
              source="unitPrice"
              label="Precio unitario"
              emptyText="-"
            />
          )}
          <NumberField source="availableStock" />
          <BooleanField source="isMarketplace" emptyText="-" />
          <BooleanField source="status" emptyText="-" />
          <WithRecord
            label="Acciones"
            render={(productsupplier: TProductSupplier) => {
              const { data: supplier } = useGetOne<TSupplier>(SUPPLIERS, {
                id: productsupplier.supplierId,
              });

              return (
                <Box>
                  {hasPermission("product.supplier.edit") && (
                    <SupplierFormAction
                      isEdit
                      product={product}
                      disabled={!!supplier?.productsAdministration}
                    />
                  )}
                  {hasPermission("product.supplier.delete") && (
                    <SupplierDeleteAction
                      disabled={!!supplier?.productsAdministration}
                    />
                  )}
                  {hasPermission("product.supplier.stock") && (
                    <SupplierStockAction
                      product={product}
                      disabled={!!supplier?.productsAdministration}
                    />
                  )}
                  {supplier?.productsAdministration && (
                    <Typography color="brown" fontSize="12px">
                      El proveedor administra sus productos
                    </Typography>
                  )}
                </Box>
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </>
  );
};

const ProductSupplierSalesField = () => {
  const productSupplier = useRecordContext();
  const refresh = useRefresh();

  return (
    <ArrayField source="sales" fullWidth>
      <Datagrid
        bulkActionButtons={false}
        empty={
          <Box mb={1}>
            No se encontraron descuentos escalonados en este distribuidor
          </Box>
        }
        resource={PRODUCTS}
      >
        <TextField source="applier" />
        <TextField source="valueType" />
        <NumberField source="minValue" />
        <NumberField source="discountPercentage" />
        <WithRecord
          label="Descuento"
          render={(sale) => (
            <NumberField
              source="value"
              record={{
                value:
                  (productSupplier?.wholesalePrice * sale.discountPercentage) /
                  100,
              }}
            />
          )}
        />
        <WithRecord
          label="Total"
          render={(sale) => (
            <NumberField
              source="value"
              record={{
                value:
                  (productSupplier?.wholesalePrice *
                    (100 - sale.discountPercentage)) /
                  100,
              }}
            />
          )}
        />
        <FunctionField
          label="Acciones"
          render={(sale: any) => (
            <Box>
              {sale.applier === "PRODUCT" && (
                <SupplierDiscountsContext.Provider
                  value={{
                    applier: sale.applier,
                    supplierId: productSupplier.supplierId,
                    reload: refresh,
                  }}
                >
                  <SupplierDiscountDeleteButton limitId={sale.id} />
                </SupplierDiscountsContext.Provider>
              )}
            </Box>
          )}
        />
      </Datagrid>
    </ArrayField>
  );
};

const ProductSupplierStockField = () => {
  return (
    <ArrayField source="warehouses">
      <Datagrid
        bulkActionButtons={false}
        empty={
          <Box mb={1}>
            No se encontraron bodegas con stock en este distribuidor
          </Box>
        }
        resource={PRODUCTS}
      >
        <TextField source="warehouseName" label="Bodega" />
        <TextField source="availableStock" />
        <TextField source="damagedStock" />
        <NumberField source="departureStock" />
        <NumberField source="reservedStock" />
      </Datagrid>
    </ArrayField>
  );
};

const SupplierDeleteAction = ({ disabled }: { disabled?: boolean }) => {
  const record = useRecordContext();
  const { admin } = useAdmin();
  const refresh = useRefresh();

  const deleteProductSupplier = () => {
    getSeeriApi().delete("/api/products/product-supplier/" + record.id, {
      data: {
        deletedBy: admin.id,
      },
    });
    refresh();
  };

  return (
    <ModalAction
      dialogTitle="Eliminar distribuidor"
      dialogMaxWidth="xs"
      buttonIcon={<Delete />}
      buttonMode="icon"
      buttonDisabled={disabled}
      dialogActions={(onClose) => (
        <Button
          label="Eliminar"
          onClick={() => {
            deleteProductSupplier();
            onClose();
          }}
        />
      )}
    />
  );
};

const SupplierFormAction: FC<{
  isEdit?: boolean;
  product: TProduct;
  disabled?: boolean;
}> = ({ isEdit = false, product, disabled = false }) => {
  const productSupplier = useRecordContext();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values: any, onClose: Function) => {
    if (loading) return;
    setLoading(true);

    try {
      const payload = {
        ...values,
        productId: product.id,
      };

      if (isEdit) {
        await getSeeriApi().put(
          "/api/products/product-supplier/" + productSupplier.id,
          payload
        );
      } else {
        await getSeeriApi().post("/api/products/product-supplier", payload);
      }
      refresh();
      onClose();
    } catch (error) {
      console.log(error);
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  let formId = "form-product-supplier-form";

  if (isEdit) {
    formId += "-" + productSupplier.id;
  }

  return (
    <ModalAction
      dialogTitle={(isEdit ? "Editar" : "Agregar") + " distribuidor"}
      buttonIcon={isEdit ? <Edit /> : <Add />}
      buttonText="Agregar distribuidor"
      buttonMode={isEdit ? "icon" : "normal"}
      buttonDisabled={disabled}
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={isEdit ? productSupplier : {}}
          onSubmit={(values) => onSubmit(values, onClose)}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <ReferenceInput source="supplierId" reference={SUPPLIERS}>
                <AutocompleteInput
                  label="Distribuidor"
                  optionText={(choice: TSupplier) =>
                    `${choice.name} [${getNTName(choice.negotiationType)}]`
                  }
                  filterToQuery={(searchText: string) => ({
                    name: searchText,
                  })}
                  fullWidth
                  validate={required()}
                  disabled={isEdit}
                />
              </ReferenceInput>
            </Grid>

            <Grid item xs={6}>
              <TextInput source="supplierSku" validate={required()} fullWidth />
            </Grid>

            <FormDataConsumer>
              {({ formData }) => {
                if (!formData.supplierId) return null;

                const sales = isEdit
                  ? productSupplier.sales
                  : useSales(formData.supplierId, product);

                return (
                  <Grid item xs={12}>
                    <Labeled label="Descuentos escalonados" fullWidth>
                      <RecordContextProvider
                        value={{
                          sales,
                          wholesalePrice: formData.wholesalePrice,
                        }}
                      >
                        <ProductSupplierSalesField />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                );
              }}
            </FormDataConsumer>

            <PricingInputs product={product} isEdit={isEdit} />

            <Grid item xs={12}>
              <Divider sx={{ margin: "1rem 0" }} />
            </Grid>

            <Grid item md={6}>
              <NumberInput
                label="Precio de compra"
                source="purchasePrice"
                fullWidth
              />
            </Grid>

            {country.code === "us" && (
              <Grid item md={6}>
                <NumberInput
                  label="Precio unitario"
                  source="unitPrice"
                  fullWidth
                />
              </Grid>
            )}

            <Grid item md={6}>
              <NumberInput
                label="Stock de seguridad"
                source="securityStock"
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <DateTimeInput
                label="Fecha de vencimiento"
                source="dueDate"
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <BooleanInput source="status" />
            </Grid>

            <Grid item md={6}>
              <BooleanInput source="disableInStockOut" disabled />
            </Grid>
          </Grid>
        </Form>
      )}
      dialogActions={
        <Box>
          <Button
            label="Guardar"
            type="submit"
            size="medium"
            form={formId}
            disabled={loading}
            startIcon={
              loading && <Refresh className={loading ? "rotate-icon" : ""} />
            }
          />
        </Box>
      }
    />
  );
};

const useSales = (supplierId: string, product: TProduct) => {
  const { data: sales = [] } = useSWR(
    supplierId
      ? [
          {
            productId: product.id,
            brandId: product.brandId,
            supplierId,
            categoryId: product.categoryId,
            sales: null,
          },
        ]
      : null,
    (payload) =>
      getSeeriApi()
        .post("/api/limits/tiered-discounts", payload)
        .then((res) => res.data[0].sales ?? [])
  );
  return sales;
};

const PricingInputs: FC<{
  product: TProduct;
  isEdit: boolean;
}> = ({ product, isEdit }) => {
  const productSupplier = useRecordContext();
  const { setValue } = useFormContext();

  const supplierId = useWatch({ name: "supplierId" });

  const { data: supplier } = useGetOne<TSupplier>(
    SUPPLIERS,
    { id: supplierId },
    { enabled: !!supplierId }
  );

  const sales = isEdit ? productSupplier.sales : useSales(supplierId, product);

  const lastDiscount = sales?.at(-1)?.discountPercentage ?? 0;

  const isPriceList = supplier?.negotiationType === "PRICE_LIST";

  return (
    <>
      {isPriceList && (
        <Grid item xs={12} md={4}>
          <NumberInput
            source={"supplierPrice"}
            validate={[required(), minValue(1, "Debe ser mayor a 0")]}
            onChange={(event) => {
              const supplierPrice = event.target.valueAsNumber;
              const wholesalePrice =
                supplierPrice /
                (1 - (supplier.priceListIncreasePercentage ?? 0) / 100);
              setValue("wholesalePrice", +wholesalePrice.toFixed(2));
            }}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={6} md={isPriceList ? 4 : 6}>
        <NumberInput
          source="wholesalePrice"
          validate={[
            required(),
            minValue(1, "Debe ser mayor a 0"),
            maxValue(
              product.retailPrice,
              `No puede ser mayor que el PVSP (${formatCurrency(
                product.retailPrice
              )})`
            ),
          ]}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={isPriceList ? 4 : 6}>
        <Labeled>
          <NumberField
            source="lastDiscount"
            record={{ lastDiscount }}
            label="Descuento Sugerido (%)"
          />
        </Labeled>
      </Grid>
    </>
  );
};

const SupplierStockAction: FC<{ product: any; disabled: boolean }> = ({
  product,
  disabled,
}) => {
  const { admin } = useAdmin();
  const supplierProduct = useRecordContext();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { data: supplierData } = useGetOne(SUPPLIERS, {
    id: record?.supplierId,
  });

  if (!supplierProduct) return null;

  const onSubmit = async (values: any, onClose: Function) => {
    if (loading) return;
    setLoading(true);

    try {
      await getSeeriApi().post(
        `/api/products/product-supplier/${supplierProduct.id}/movements`,
        {
          amount: values.amount,
          [values.provisioningType]: "AVAILABLE",
          type: "PROVISIONING",
          createdBy: admin.email,
          warehouseId: values.warehouseId,
        }
      );
      refresh();
      notify("Stock agregado");
      onClose();
    } catch (error) {
      console.log(error);
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "form-product-supplier-sku-" + supplierProduct.id;

  return (
    <ModalAction
      dialogMaxWidth="xs"
      buttonMode="icon"
      buttonIcon={<Add />}
      buttonDisabled={disabled}
      dialogTitle="Aprovisionamiento"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={{}}
          onSubmit={(values) => onSubmit(values, onClose)}
        >
          <RadioButtonGroupInput
            label="Elige una opción"
            source="provisioningType"
            defaultValue={"destiny"}
            choices={[
              { text: "Agregar", value: "destiny" },
              { text: "Quitar", value: "source" },
            ]}
            optionText="text"
            optionValue="value"
          />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <NumberInput
                  label="Cantidad"
                  source="amount"
                  validate={[
                    required(),
                    number(),
                    minValue(0),
                    ...(formData.provisioningType === "source"
                      ? [maxValue(supplierProduct.availableStock)]
                      : []),
                    ...(product?.partialUnitsAllowed ? [] : [integer()]),
                  ]}
                  step={product?.partialUnitsAllowed ? 0.1 : 1}
                  fullWidth
                />
                <SelectInput
                  source="warehouseId"
                  choices={(supplierData?.addresses ?? []).map(
                    (address: any) => ({
                      id: address.id,
                      name: `${address.addressLine1 ?? "-"}, ${
                        address.addressLine2 ?? "-"
                      } ;
            ${address.city ?? "-"}, ${address.country ?? "-"}`,
                    })
                  )}
                  validate={required()}
                  fullWidth
                />
              </>
            )}
          </FormDataConsumer>
        </Form>
      )}
      dialogActions={
        <Button
          label="Guardar"
          type="submit"
          size="medium"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};

export const DeliveryTimes = () => {
  return (
    <ArrayField source="deliveryTimes" fullWidth>
      <Datagrid
        bulkActionButtons={false}
        empty={
          <Box mb={1}>
            No se encontraron tiempos de entrega en este distribuidor
          </Box>
        }
      >
        <TextField source="city" />
        <FunctionField
          source="days"
          render={({ days }: { days: number }) =>
            days === 0 ? "Mismo día" : <NumberField source="days" />
          }
        />
      </Datagrid>
    </ArrayField>
  );
};
