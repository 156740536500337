import { FC } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormTab,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { Grid } from "@mui/material";
import { OptimizedImageInput } from "../../components/elements/OptimizedImageInput";
import { BRANDS } from "../../config/resources";
import { TaxTypesProductInput } from "./inputs/tax";
import {
  CategoryAutocompleteArrayInput,
  CategoryAutocompleteInput,
} from "../shared/category";
import { useFormContext, useWatch } from "react-hook-form";
import { productTypeChoices } from "../../data/products";
import { formatOptions, OptionsInput } from "./inputs/options";
import { getBrandById, TProductGroup } from "@/api";
import { ProductsInput } from "./inputs/products";

const FormToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const ProductGroupForm: FC = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext<TProductGroup>();

  if (!record && !hasPermission("productGroup.create")) return null;
  if (record && !hasPermission("productGroup.update")) return null;

  const isEdit = !!record;

  const customRecord = record
    ? { ...record, options: formatOptions(record.options) }
    : record;

  return (
    <TabbedForm
      mode="all"
      record={customRecord}
      toolbar={<FormToolbar />}
      warnWhenUnsavedChanges
    >
      <FormTab label="Principal">
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={6}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <BrandInput />
          </Grid>
          <Grid item xs={12} md={12}>
            <ProductGroupCategoryInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <TaxTypesProductInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              source="type"
              choices={productTypeChoices}
              validate={required()}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <BooleanInput
              source="status"
              defaultValue={true}
              disabled={isEdit}
              helperText={
                isEdit && (
                  <>
                    El estado de grupo no se puede cambiar de manera manual.
                    <br />
                    Se prende automáticamente con al menos una variante
                    prendida.
                    <br /> Se apaga automáticamente cuando todas las variantes
                    están apagadas.
                  </>
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="featured"
              defaultValue={false}
              disabled={["PENDING", "IN_REVIEW"].includes(
                record?.approvedStatus
              )}
            />
          </Grid>
        </Grid>
      </FormTab>

      <FormTab label="Imágenes">
        <ArrayInput source="images" defaultValue={[]} fullWidth>
          <SimpleFormIterator disableClear fullWidth>
            <OptimizedImageInput
              source=""
              validate={required()}
              width={800}
              height={800}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Opciones">
        <OptionsInput />
      </FormTab>

      {!record && (
        <FormTab label="Variantes">
          <ProductsInput />
        </FormTab>
      )}

      <FormTab label="Categorías Visibles">
        <Grid container columnSpacing={4}>
          <Grid item xs={12}>
            <VisibleCategoriesInput />
          </Grid>
        </Grid>
      </FormTab>

      <FormTab label="Keywords">
        <ArrayInput source="keyWords">
          <SimpleFormIterator disableClear>
            <TextInput source="" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};

const ProductGroupCategoryInput = () => {
  const visibleCategories = useWatch({ name: "visibleCategories" });
  const { setValue } = useFormContext();

  return (
    <CategoryAutocompleteInput
      source="categoryId"
      label="Categoría"
      validate={required()}
      onChange={(newCategoryId: string) => {
        if (newCategoryId && !visibleCategories.includes(newCategoryId)) {
          setValue("visibleCategories", [...visibleCategories, newCategoryId]);
        }
      }}
    />
  );
};

const VisibleCategoriesInput = () => {
  return (
    <CategoryAutocompleteArrayInput
      source="visibleCategories"
      label="Categorías visibles"
    />
  );
};

const BrandInput = () => {
  const { setValue } = useFormContext();

  return (
    <ReferenceInput
      source="brandId"
      reference={BRANDS}
      sort={{ field: "name", order: "ASC" }}
      perPage={100}
    >
      <AutocompleteInput
        optionText="name"
        validate={required()}
        filterToQuery={(searchText: string) => ({ name: searchText })}
        fullWidth
        onChange={async (brandId) => {
          const brand = await getBrandById(brandId);
          setValue("supplierId", brand.supplierId);
        }}
      />
    </ReferenceInput>
  );
};
