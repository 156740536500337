import {
  Alert,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrayInput,
  AutocompleteInput,
  Labeled,
  maxValue,
  minValue,
  number,
  NumberField,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TextField,
  useGetList,
  useGetOne,
  useSimpleFormIteratorItem,
} from "react-admin";
import {
  PRODUCTS,
  SELLER_ADDRESSES,
  SUPPLIERS,
} from "../../../config/resources";
import { formatCurrency } from "../../../utils/currency";
import { integer } from "../../../utils/input-validators";
import { ProductOptionRenderer } from "../../shared/product";
import { useWatch, useFormContext } from "react-hook-form";
import {
  TPrepurchaseProductV2,
  TProductSupplier,
  TSellerAddress,
  getProductV2,
} from "@/api";
import { FC, useEffect, useRef } from "react";
import { get } from "lodash";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { purchaseFormAccordion, purchaseFormProductsRefs } from "./atoms";
import { Edit } from "@mui/icons-material";
import useSWR from "swr";
import { useAdmin } from "@/hooks/admin";

type TPurchaseProductsArrayInput = {
  includeCity?: boolean;
};
export const PurchaseProductsArrayInput = ({
  includeCity,
}: TPurchaseProductsArrayInput) => {
  const products = useWatch({ name: "products" });
  const addressId = useWatch({ name: "addressId" });

  return (
    <>
      {addressId || !includeCity ? (
        <ArrayInput
          source="products"
          defaultValue={[{ id: "", quantity: 1 }]}
          fullWidth
        >
          <SimpleFormIterator
            disableClear
            disableRemove={products?.length <= 1}
            fullWidth
          >
            <PurchaseProductInputs />
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        <Alert severity="warning">Elige una dirección para ver productos</Alert>
      )}
    </>
  );
};

const PurchaseProductInputs = () => {
  const products = (useWatch({ name: "products" }) as any[]) || [];
  const { index } = useSimpleFormIteratorItem();
  const productInputsRef = useRef<HTMLInputElement>(null);
  const setProductsInputsRef = useSetAtom(purchaseFormProductsRefs);

  useEffect(() => {
    setProductsInputsRef((prev) => ({ ...prev, [index]: productInputsRef }));
  }, [products]);

  return (
    <Box
      ref={productInputsRef}
      sx={{ width: "100%", transition: "background-color 0.5s ease" }}
    >
      <ProductItemIdInput />
      <ProductItemQuantityInput />
    </Box>
  );
};

const ProductItemIdInput = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const addressId = useWatch({ name: "addressId" });

  const { data: addresses } = useGetList<TSellerAddress>(
    SELLER_ADDRESSES,
    { meta: { dependentId: sellerId } },
    { enabled: !!sellerId }
  );
  const address = addresses?.find((a) => a.id === addressId) ?? null;

  const products = (useWatch({ name: "products" }) as any[]) || [];
  const { index } = useSimpleFormIteratorItem();
  const { setValue } = useFormContext();

  const alreadySelected = products
    .filter((_, i) => i !== index)
    .map((p) => p.id);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            reference={PRODUCTS}
            source={`products.${index}.id`}
            sort={{ field: "name", order: "ASC" }}
            queryOptions={{ meta: { mode: "search", city: address?.city } }}
            perPage={50}
          >
            <AutocompleteInput
              label="Buscar producto"
              onChange={async (productId: string) => {
                if (!productId) {
                  setValue(`products.${index}.supplierId`, "");
                  return;
                }
                const product = await getProductV2(productId, {
                  marketplace: true,
                });
                setValue(
                  `products.${index}.supplierId`,
                  product?.suppliers.find((ps: any) => ps.status)?.supplierId ??
                    ""
                );
              }}
              validate={[required()]}
              filterToQuery={(searchText: string) => ({
                name: searchText,
                marketplace: true,
              })}
              matchSuggestion={() => true}
              optionText={<ProductOptionRenderer ignoreStatus />}
              inputText={(p: any) => p?.name + " " + p?.sku}
              shouldRenderSuggestions={(v: string) => v?.trim().length >= 1}
              noOptionsText="No hay resultados"
              optionValue="id"
              fullWidth
              getOptionDisabled={(p: any) => alreadySelected.includes(p?.id)}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductItemSupplierInput />
        </Grid>
      </Grid>
    </>
  );
};

export const ProductItemSupplierInput: FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const { trigger } = useFormContext();
  const { index } = useSimpleFormIteratorItem();
  const productId = useWatch({ name: `products.${index}.id` });
  const supplierId = useWatch({ name: `products.${index}.supplierId` });

  const { data: product } = useSWR(
    productId ? { productId } : null,
    ({ productId }) => getProductV2(productId, { marketplace: true })
  );

  useEffect(() => {
    trigger(`products.${index}.quantity`);
  }, [supplierId]);

  return (
    <AutocompleteInput
      source={`products.${index}.supplierId`}
      label="Distribuidor"
      choices={product?.suppliers ?? []}
      inputText={(ps: TProductSupplier) => ps?.supplierName ?? "-"}
      optionText={(ps: TProductSupplier) =>
        ps?.supplierName + " - " + formatCurrency(ps.wholesalePrice)
      }
      optionValue="supplierId"
      emptyText="No hay distribuidores"
      filterToQuery={(searchText: string) => ({
        name: searchText,
      })}
      getOptionDisabled={(ps) => !ps?.status}
      validate={[required()]}
      disabled={disabled || !product}
      fullWidth
    />
  );
};

export const ProductItemQuantityInput: FC<{
  disabled?: boolean;
  current?: number;
}> = ({ disabled, current = 0 }) => {
  const { hasPermission } = useAdmin();
  const { index } = useSimpleFormIteratorItem();
  const productId = useWatch({ name: `products.${index}.id` });
  const supplierId = useWatch({ name: `products.${index}.supplierId` });

  const { data: product } = useSWR(
    productId ? { productId } : null,
    ({ productId }) => getProductV2(productId, { marketplace: true })
  );

  const productSupplier = product?.suppliers.find(
    (s: any) => s.supplierId === supplierId
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <NumberInput
          source={`products.${index}.quantity`}
          min={1}
          validate={[
            required(),
            number(),
            minValue(product?.partialUnitsAllowed ? 0.1 : 1),
            ...(product?.partialUnitsAllowed ? [] : [integer()]),
            ...(productSupplier?.disableInStockOut
              ? [
                  maxValue(
                    current + Math.max(productSupplier.availableStock, 0)
                  ),
                ]
              : []),
          ]}
          step={product?.partialUnitsAllowed ? 0.1 : 1}
          defaultValue={1}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Labeled>
          <TextField
            label="Stock disponible"
            source="availableStock"
            record={productSupplier}
            emptyText="-"
          />
        </Labeled>
      </Grid>
      {/* <Grid item xs={3}>
        <Labeled>
          <NumberField
            label="Precio"
            source="wholesalePrice"
            record={productSupplier}
            emptyText="-"
          />
        </Labeled>
      </Grid> */}
      {hasPermission("purchase.create.mandatoryDiscount") && (
        <Grid item xs={3}>
          <NumberInput
            label="Actualizar precio"
            source={`products.${index}.unitPrice`}
            helperText="Opcional"
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};

export const PurchaseProductBrandArrayInput = ({
  products,
}: {
  products: TPrepurchaseProductV2[];
}) => {
  const [expanded, setExpanded] = useAtom(purchaseFormAccordion);
  const { getValues } = useFormContext();
  const productsRefs = useAtomValue(purchaseFormProductsRefs);

  return (
    <List sx={{ padding: 0 }}>
      {products.map((product) => (
        <ListItem key={product.id} sx={{ padding: 0 }}>
          <ListItemText
            primary={
              <>
                {product.name}{" "}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    const index = getValues().products?.findIndex(
                      (p: any) => p.id === product.id
                    );
                    setExpanded("products");
                    setTimeout(
                      () => {
                        const box =
                          productsRefs[index]?.current?.parentElement
                            ?.parentElement;
                        if (!box) return;
                        box.scrollIntoView({
                          block: "center",
                          behavior: "smooth",
                        });
                        box.style.transition = "background-color 0.5s ease-out";
                        box.style.backgroundColor = "yellow";
                        setTimeout(() => {
                          box.style.backgroundColor = "transparent";
                        }, 500);
                      },
                      expanded !== "products" ? 400 : 0
                    );
                  }}
                >
                  <Edit />
                </IconButton>
              </>
            }
            secondary={
              <span>
                Distribuidor:{" "}
                <ReferenceData
                  resource={SUPPLIERS}
                  id={product.supplierId}
                  source="name"
                />
                <br />
                Precio: {formatCurrency(product.wholesalePrice)}
                <br />
                Cantidad: {product.quantity}
              </span>
            }
          />
          <Box textAlign={"right"}>
            {product.totalDiscount > 0 && (
              <Typography fontSize={"13px"} color="gray">
                <strong>
                  (-
                  {((product.totalDiscount / product.subtotal) * 100).toFixed(
                    2
                  )}
                  %)
                </strong>{" "}
                <span style={{ textDecoration: "line-through" }}>
                  {formatCurrency(product.subtotal)}
                </span>
              </Typography>
            )}
            {formatCurrency(product.subTotalWithDiscount)}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

const ReferenceData: FC<{ resource: string; id: string; source: string }> = ({
  resource,
  id,
  source,
}) => {
  const { data } = useGetOne(resource, { id });
  return get(data, source) ?? "-";
};
